<template>
    <!-- navbar-->
    <header class="header" ref="header">
        <!-- Primary Navbar-->
        <nav class="navbar navbar-expand-lg navbar-light py-4 border-bottom border-gray bg-white pt-0 pb-2">
            <div class="container d-flex align-items-center justify-content-between">
                <a class="navbar-brand"><img src="../assets/img/logo_neu.png" alt="" width="360"></a>
                <button class="navbar-toggler" type="button" @click="toggleNavBar"><span
                        class="navbar-toggler-icon"></span></button>
                <ul class="list-inline mb-0 mt-3 d-none d-lg-block">
                    <li class="list-inline-item mr-4">
                        <div class="d-flex">
                            <span class="h4 text-muted">
                                <i class="fas fa-phone"></i>
                            </span>
                            <div class="ms-2">
                                <h6 class="text-muted text-uppercase mb-0 text-sm">
                                    <a class="text-muted" v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone')
                                    }}</a>
                                </h6>
                                <p class="small text-gray mb-0">{{ $t('frame.call_us') }}</p>
                            </div>
                        </div>
                    </li>
                    <li class="list-inline-item me-3">
                        <div class="d-flex">
                            <span class="h4 text-muted">
                                <i class="far fa-clock"></i>
                            </span>
                            <div class="ms-2">
                                <h6 class="text-muted text-uppercase mb-2 text-sm">Telefon</h6>
                                <table class="small text-gray">
                                    <tr>
                                        <td class="text-right pr-2">Mo-Fr:</td>
                                        <td>08-12</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </li>
                    <li class="list-inline-item">
                        <div class="d-flex">
                            <span class="h4 text-white">
                                <i class="far fa-clock"></i>
                            </span>
                            <div class="ms-2">
                                <h6 class="text-muted text-uppercase mb-2 text-sm">Praxis</h6>
                                <table class="small text-gray">
                                    <tr>
                                        <td class="pr-2 bordered">Mo-Do:</td>
                                        <td class="pl-2">Fr:</td>
                                    </tr>
                                    <tr>
                                        <td class="pr-2 bordered">08-12 | 13-18 Uhr</td>
                                        <td class="pl-2">08-12 Uhr</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- Secondary Navbar                -->
        <nav class="navbar navbar-expand-lg navbar-light py-2 shadow-sm bg-white">
            <div class="container">
                <div v-bind:class="{ collapse: !show_nav }" class="navbar-collapse py-3 py-lg-0" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto">
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/" exact>{{ $t('frame.home')
                                }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/ueber" exact>{{
                                    $t('frame.about') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/leistungen">{{
                                    $t('frame.services') }}</router-link>
                        </li>
                        <!--
                <b-nav-item-dropdown
                ref="services_dropdown"
                v-bind:text="$t('frame.services')"
                :class="{'router-link-exact-active router-link-active': $route.name === 'Leistungen'}"
                class="text-uppercase letter-spacing-0"
                menu-class="dropdown-menu border-0 border-top border-2 border-primary shadow-sm mt-0"
                left
                >
                <router-link to="/leistungen#uebersicht" tag="li" exact><a v-on:click="dropdownClicked" class="dropdown-item">Übersicht</a></router-link>
                <router-link to="/leistungen#physio" tag="li" exact><a v-on:click="dropdownClicked" class="dropdown-item">Physiotherapie</a></router-link>
                <router-link to="/leistungen#ergo" tag="li" exact><a v-on:click="dropdownClicked" class="dropdown-item">Ergotherapie</a></router-link>
                <router-link to="/leistungen#podo" tag="li" exact><a v-on:click="dropdownClicked" class="dropdown-item">Podologie</a></router-link>
                <router-link to="/leistungen#cosmetic" tag="li" exact><a v-on:click="dropdownClicked" class="dropdown-item">Medizinische Kosmetik</a></router-link>
                <router-link to="/leistungen#hausbesuche" tag="li" exact><a v-on:click="dropdownClicked" class="dropdown-item">Hausbesuche</a></router-link>
            </b-nav-item-dropdown>
        -->
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/karriere" exact>{{
                                    $t('frame.career') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/loesungen">{{
                                    $t('frame.solutions') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/documents" exact>{{
                                    $t('frame.documents') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/kontakt" exact>{{
                                    $t('frame.contact') }}</router-link>
                        </li>
                        <!--
                        <li class="nav-item">
                            <router-link tag="a" class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important" class-active="active" to="/blog" exact>{{
                                    $t('frame.blog') }}</router-link>
                        </li>
                        -->
                        <li class="nav-item">
                            <a href="https://fast-ant-f48.notion.site/6823e76b924b4f1299477b599933559c?v=6164ec662c864ec989c1d40f3a206616"
                                class="nav-link text-uppercase letter-spacing-0"
                                style="font-size: 1.1rem !important"
                                target="_blank">
                                {{ $t('frame.aktuellt') }}
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a class="nav-link text-uppercase letter-spacing-0 border-start px-4 bg-success text-white d-inline-block"
                                href="/#termin">
                                <i class="fas fa-hand-point-right">
                                </i>
                                <span>
                                    {{ $t('frame.appointment') }}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'HeaderLayout',
    mounted() {
    },
    data: () => ({
        show_nav: false
    }),
    methods: {
        toggleNavBar() {
            this.show_nav = !this.show_nav;
        },
        dropdownClicked() {
            this.$refs.services_dropdown.hide();
        }
    }
};
</script>

<style scoped>.bordered {
    border-right: 1px solid #a0a0a0;
}</style>
